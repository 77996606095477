import {
  Avatar,
  Flexbox,
  ListManager, PageHeader, Paginator, Styles,
} from '@/components'
import { resultColorMap, statusColorMap } from '@/constants/mistreatment'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import { MISTREATMENT } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import mistreatments from '@/services/api/mistreatments'
import React from 'react'
import { Link } from 'react-router-dom'

// import { Container } from './styles';

function Mistreatments({ title }) {
  usePageTitle({ title })

  const query = useQuery()

  const { data, loading, error } = useAxiosRequest(mistreatments.list, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader title="Denúncias de maus tratos" subtitle="Lista das denúncias de maus tratos" icon="fas fa-bullhorn" />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Detalhes</th>
                <th width="150px">Situações</th>
                <th width="150px">Denunciado em</th>
                <th width="100px" className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                Boolean(data) && data.rows.map((mistreatment) => (
                  <tr key={mistreatment.id}>
                    <td>
                      <div>
                        {
                          mistreatment.anonymous ? (
                            <div>Denúncia anônima</div>
                          ) : (
                            <Flexbox.Columns className="align-center col-gapless">
                              <Flexbox.Column className="col-auto">
                                <Avatar
                                  avatarSize="sm"
                                  name={mistreatment.author.fullName}
                                  photoURL={mistreatment.author.photoURL}
                                />
                              </Flexbox.Column>
                              <Flexbox.Column className="px-1">{mistreatment.author.fullName}</Flexbox.Column>
                            </Flexbox.Columns>
                          )
                        }
                        <div className="text-small text-ellipsis">{mistreatment?.description}</div>
                        <div className="text-small">
                          <small>{mistreatment?.address?.formattedAddress}</small>
                        </div>
                        <div className="text-small">
                          <small>
                            <i className="fas fa-image" />
                            <span> x{mistreatment?.images?.length ?? 'Nenhuma'}</span>
                          </small>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={statusColorMap[mistreatment?.status]}>
                        {MISTREATMENT.status.t(mistreatment.status)}
                      </div>
                      <div className={resultColorMap[mistreatment?.result]}>
                        {MISTREATMENT.result.t(mistreatment.result)}
                      </div>
                    </td>
                    <td>
                      {dateMask(mistreatment.createdAt)}
                    </td>
                    <td className="text-right">
                      <Link
                        to={`/app/mistreatments/${mistreatment.id}`}
                        className="btn btn-action btn-sm btn-primary"
                      >
                        <i className="fas fa-eye" />
                      </Link>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default Mistreatments
