import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import yup from '@/lib/yupPt'
import { toast } from 'react-toastify'
import formatAddress from '@/lib/formatters/address'
import gmaps from '@/services/gmaps'
import Form from '../Form'
import AddressForm from '../AddressForm'

const schema = yup.object().shape({
  address: yup.object().shape({
    zipcode: yup.string().trim().required().length(8),
    city: yup.object().shape({
      id: yup.number().required(),
      name: yup.string(),
      uf: yup.string(),
    }),
    street: yup.string().trim().required(),
    number: yup.string().trim().required(),
    complement: yup.string().trim(),
  }),
})
export default function AddressPicker({ title, address, onChange }) {
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (body) => {
    try {
      setLoading(true)
      const formattedAddress = formatAddress(body?.address)
      const { location } = await gmaps.geolocation(formattedAddress)
      const { lat, lng } = location
      const point = { type: 'Point', coordinates: [lng, lat] }
      onChange({ ...body.address, location: point, formattedAddress })
      setActive(false)
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }, [onChange])

  return (
    <div>
      {
        address
          ? (
            <>
              <div className="bg-gray p-1 s-rounded" style={{ display: 'inline-block' }}>
                <small>
                  {formatAddress(address)}
                </small>
                <a className="btn btn-link btn-action btn-sm ml-2 tooltip tooltip-top" data-tooltip="Editar endereço" onClick={() => setActive(true)}>
                  <i className="fas fa-edit" />
                </a>
                <a className="btn btn-link btn-action btn-sm text-error tooltip tooltip-top" data-tooltip="Remover endereço" onClick={() => onChange(null)}>
                  <i className="fas fa-trash" />
                </a>
              </div>
            </>
          )
          : (
            <a className="c-hand" onClick={() => setActive(true)}>
              <i className="fas fa-edit" />
              &nbsp;Informar endereço
            </a>
          )
      }
      {
        active && (
          <fieldset disabled={loading}>
            <Form.Formik
              initialValues={address ? { address } : {
                address: {
                  zipcode: '',
                  city: {
                    id: '',
                    name: '',
                    uf: '',
                  },
                  street: '',
                  district: '',
                  number: '',
                  complement: '',
                },
              }}
              validationSchema={schema}
              onSubmit={onSubmit}
              validateOnMount
            >
              {
                ({
                  touched, errors, values, setFieldValue, isValid,
                }) => (
                  <Form.ValidationForm>
                    <div className={`modal ${active ? 'active' : ''}`}>
                      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => setActive(false)} />
                      <div className="modal-container">
                        <div className="modal-header">
                          <a
                            type="button"
                            className="btn btn-clear float-right"
                            onClick={() => setActive(false)}
                          />
                          <div className="modal-title h5">
                            {title}
                          </div>
                        </div>
                        <div className="modal-body">
                          <AddressForm
                            touched={touched}
                            errors={errors}
                            value={values.address}
                            onChange={setFieldValue}
                          />
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-primary" disabled={!isValid}>
                            <i className="fas fa-check" />
                            &nbsp;Confirmar
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form.ValidationForm>
                )
              }
            </Form.Formik>
          </fieldset>
        )
      }
    </div>
  )
}

AddressPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  address: PropTypes.objectOf(Object),
}

AddressPicker.defaultProps = {
  address: null,
}
