import { AnimalPicker, Styles } from '@/components'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import useClinicalCare from '@/hooks/useClinicalCare'
import { setClinicalCare } from '@/reducers/clinicalCare'
import clinicalCares from '@/services/api/clinicalCares'
import { clinicalCareUrl } from '@/lib/urlMaker'
import allowedActions from './allowedActions'

function Actions({ clinicalCare }) {
  const actions = allowedActions(clinicalCare?.status)
  const { pathname } = useLocation()
  const { dispatch } = useClinicalCare()

  const [loading, setLoading] = useState(false)

  async function onSetAnimal(animal) {
    try {
      setLoading(true)
      const { data } = await clinicalCares.setAnimal(clinicalCare.id, animal)
      dispatch(setClinicalCare(data))
      toast.success('Pet informado com sucesso')
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  async function onSelectAnimal(animal) {
    confirmAlert({
      title: 'Confirmar Pet',
      message: `Você está certo que deseja informar o pet ${animal.name} no atendimento?`,
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim',
          className: 'bg-success',
          onClick: () => onSetAnimal(animal),
        },
      ],
    })
  }

  if (loading) {
    return (
      <Styles.ActionsContainer>
        <div className="loading mr-2 pr-2" /> <cite className="ml-2">Informando pet</cite>
      </Styles.ActionsContainer>
    )
  }

  return (
    <Styles.ActionsContainer>
      {
        actions.includes('approve') && Boolean(clinicalCare.animal) && (
          <Link className="btn btn-sm btn-success mx-1" to={`${pathname}/approve`}>
            <i className="fas fa-check" />
            &nbsp;Encaminhar para a clínica
          </Link>
        )
      }
      {
        actions.includes('release') && Boolean(clinicalCare.animal) && (
          <Link className="btn btn-sm btn-success mx-1" to={`${pathname}/release`}>
            <i className="fas fa-briefcase-medical" />
            &nbsp;Informar alta
          </Link>
        )
      }
      {
        actions.includes('setAnimal') && !clinicalCare.animal && (
          <div className="mr-2">
            <AnimalPicker onChange={onSelectAnimal} animal={null} title="Informar pet para o atendimento" />
          </div>

        )
      }
      {
        actions.includes('pullout') && (
          <Link className="btn btn-sm btn-success mx-1" to={`${pathname}/pullout`}>
            <i className="fas fa-ambulance" />
            &nbsp;Retirar pet
          </Link>
        )
      }
      {
        actions.includes('done') && (
          <Link className="btn btn-sm btn-success mx-1" to={`${pathname}/done`}>
            <i className="fas fa-check" />
            &nbsp;Concluir atendimento
          </Link>
        )
      }
      {
        actions.includes('cancel') && (
          <Link className="btn btn-sm btn-error mx-1" to={`${pathname}/cancel`}>
            <i className="fas fa-times" />
            &nbsp;Cancelar
          </Link>
        )
      }
      {
        actions.includes('death') && Boolean(clinicalCare.animal) && (
          <Link className="btn btn-sm mx-1" to={`${pathname}/death`}>
            <i className="fas fa-cross" />
            &nbsp;Pet faleceu
          </Link>
        )
      }
      <a className="btn btn-sm btn-link mx-1" href={clinicalCareUrl(clinicalCare)} target="_blank" rel="noopener noreferrer">
        <i className="fas fa-print" />
        &nbsp;Imprimir
      </a>
    </Styles.ActionsContainer>
  )
}

Actions.propTypes = {
  clinicalCare: PropTypes.objectOf(Object).isRequired,
}

export default Actions
