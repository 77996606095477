import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import InputCep from './InputCep'
import Flexbox from '../Flexbox'
import Form from '../Form'

function AddressForm({
  touched, errors, value, onChange,
}) {
  const handleFoundAddress = useCallback((address) => {
    onChange('address', { ...value, ...address })
  }, [onChange, value])

  return (
    <Flexbox.Columns>
      <Flexbox.Column className="col-4">
        <InputCep
          onFoundAddress={handleFoundAddress}
          value={value?.zipcode}
          touched={touched}
          errors={errors}
          onChange={onChange}
        />
      </Flexbox.Column>
      <Flexbox.Column className="col-8">
        <Form.Group>
          <Form.Label htmlFor="city">Cidade <span className="text-error">*</span></Form.Label>
          <Form.Input disabled value={value?.city?.id ? `${value?.city?.name} - ${value?.city?.uf}` : ''} />
        </Form.Group>
      </Flexbox.Column>
      <Flexbox.Column className="col-12">
        <Form.Group className={`${touched?.district && errors?.district ? 'has-error' : ''}`}>
          <Form.Label htmlFor="district">Bairro <span className="text-error">*</span></Form.Label>
          <Form.ValidationField
            name="address.district"
            id="district"
            placeholder="Bairro"
          />
          {
            touched?.district && errors?.district
              ? <Form.Hint className="text-error">{errors.district}</Form.Hint> : null
          }
        </Form.Group>
      </Flexbox.Column>
      <Flexbox.Column className="col-12">
        <Form.Group className={`${touched?.street && errors?.street ? 'has-error' : ''}`}>
          <Form.Label htmlFor="street">Logradouro <span className="text-error">*</span></Form.Label>
          <Form.ValidationField
            name="address.street"
            id="street"
            placeholder="Logradouro"
          />
          {
            touched?.street && errors?.street
              ? <Form.Hint className="text-error">{errors.street}</Form.Hint> : null
          }
        </Form.Group>
      </Flexbox.Column>
      <Flexbox.Column className="col-6">
        <Form.Group className={`${touched?.number && errors?.number ? 'has-error' : ''}`}>
          <Form.Label htmlFor="number">Número <span className="text-error">*</span></Form.Label>
          <Form.ValidationField
            name="address.number"
            id="number"
            placeholder="Número"
          />
          {
            touched?.number && errors?.number
              ? <Form.Hint className="text-error">{errors.number}</Form.Hint> : null
          }
        </Form.Group>
      </Flexbox.Column>
      <Flexbox.Column className="col-6">
        <Form.Group className={`${touched?.complement && errors?.complement ? 'has-error' : ''}`}>
          <Form.Label htmlFor="complement">Complemento</Form.Label>
          <Form.ValidationField
            name="address.complement"
            id="complement"
            placeholder="Complemento"
          />
          {
            touched?.complement && errors?.complement
              ? <Form.Hint className="text-error">{errors.complement}</Form.Hint> : null
          }
        </Form.Group>
      </Flexbox.Column>
    </Flexbox.Columns>
  )
}

AddressForm.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.objectOf(Object),
  touched: PropTypes.objectOf(Object),
  errors: PropTypes.objectOf(Object),
}

AddressForm.defaultProps = {
  onChange: () => { },
  value: {},
  touched: {},
  errors: {},
}

export default AddressForm
