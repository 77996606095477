import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Flexbox } from '@/components'
import documentNumberMask from '@/lib/masks/documentNumber'
import dateMask from '@/lib/masks/date'
import { Link } from 'react-router-dom'
import { clinicalCareStatus, clinicalCareStatusClasses } from '@/constants/clinicalCares'
import currency from '@/lib/masks/currency'

export default function Row({ clinicalCare }) {
  return (
    <tr>
      <td>
        <Flexbox.Columns className="align-center col-gapless">
          {
            clinicalCare?.animal
              ? (
                <>
                  <Flexbox.Column className="col-auto">
                    <Avatar
                      className="mr-1"
                      name={clinicalCare?.animal?.name}
                      photoURL={clinicalCare?.animal?.photoURL}
                      alt="Imagem do pet"
                    />
                  </Flexbox.Column>
                  <Flexbox.Column className="ml-1">
                    {clinicalCare?.animal?.name}
                    <div>
                      <small>
                        {clinicalCare?.animal?.category?.description}
                        &nbsp;-&nbsp;
                        {clinicalCare?.animal?.breed?.description}
                      </small>
                    </div>
                  </Flexbox.Column>
                </>
              )
              : (
                <div className="text-center text-bold">Não informado</div>
              )

          }
        </Flexbox.Columns>
      </td>
      <td>
        <Flexbox.Columns className="align-center col-gapless">
          <Flexbox.Column className="col-auto">
            <Avatar
              className="mr-1"
              name={clinicalCare.clinic.fullName}
              photoURL={clinicalCare.clinic.photoURL}
              alt="Imagem do pet"
            />
          </Flexbox.Column>
          <Flexbox.Column className="ml-1">
            {clinicalCare.clinic.fullName}
            <div>
              <small>
                {documentNumberMask(clinicalCare.clinic.documentNumber)}
              </small>
            </div>
          </Flexbox.Column>
        </Flexbox.Columns>
      </td>
      <td className={clinicalCareStatusClasses?.[clinicalCare.status]}>
        {clinicalCareStatus?.[clinicalCare.status] || clinicalCare.status}
      </td>
      <td>{dateMask(clinicalCare.createdAt)}</td>
      <td className="text-right">{currency(clinicalCare.price / 100, 'R$ ')}</td>
      <td className="text-right">
        <Link
          to={`/app/clinical-cares/show/${clinicalCare.id}`}
          className="btn btn-action btn-sm btn-primary"
        >
          <i className="fas fa-eye" />
        </Link>
      </td>
    </tr>
  )
}

Row.propTypes = {
  clinicalCare: PropTypes.objectOf(Object).isRequired,
}
